import React, { ReactNode } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

interface LayoutProps {
  children: ReactNode;
}

interface NavigationItem {
  name: string | JSX.Element;
  href: string;
  current: boolean;
  target?: string
}

const navigation: NavigationItem[] = [
  { name: 'Home', href: '/', current: true },
  { name: 'About Us', href: '/about-us/', current: false },
  { name: 'Solutions', href: '/solutions/', current: false },
  { name: 'Contact Us', href: '/contact-us/', current: false },
  { name: <img width={100} src="/images/gensol-icon.png" alt="Gensol" />, href: 'https://www.gensol.in/', current: false, target:"_blank" },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const RootLayout: React.FC<LayoutProps> = ({ children }) => {
  const [currentPath, setCurrentPath] = React.useState<string>(window.location.pathname);

  // Update navigation items to reflect current page
  const updatedNavigation = navigation.map((item) => ({
    ...item,
    current: currentPath === item.href,
  }));

  return (
    <>
      <Disclosure as="nav" >
          <div className="mx-auto max-w-full px-2 sm:px-6 lg:px-8 py-4">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                  <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                </DisclosureButton>
              </div>
              <div className="flex flex-1 items-center sm:justify-between justify-center">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/">
                    <img src="/images/lets-ev-logo.png" className="h-[60px]" alt="Flowbite Logo" />
                  </a>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-6 h-full items-center">
                    {updatedNavigation.map((item, key) => (
                      <a
                        key={key}
                        href={item.href}
                        target={item?.target}
                        aria-current={item.current ? 'page' : undefined}
                        className={classNames(
                          item.current ? 'font-poppinsBold' : 'underline-from-left',
                          'rounded-3xl py-1 font-poppins text-base text-blue cursor-pointer',
                        )}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>

            </div>
          </div>

          <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item, key) => (
                <DisclosureButton
                  key={key}
                  as="a"
                  href={item.href}
                  aria-current={item.current ? 'page' : undefined}
                  className={classNames(
                    item.current ? 'bg-blue text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium',
                  )}
                >
                  {item.name}
                </DisclosureButton>
              ))}
            </div>
          </DisclosurePanel>
        </Disclosure>

        <div className="max-w-screen-3xl flex flex-wrap items-center justify-between mx-auto">
          {children}
        </div>

        <div className="max-w-screen-3xl flex flex-wrap items-center justify-between mx-auto">
          <div className='py-10 sm:px-32 px-4 bg-blue relative overflow-hidden w-full'>
            <div className='relative z-50 flex sm:flex-row flex-col text-white font-poppins'>
              <div className='sm:pr-14 pr-4'>
                <img src="/images/lets-ev-logo-white.png" className="sm:w-[240px] w-[120px]" />

                <p className='sm:text-base text-xs mt-7'>Delivering comprehensive EV Leasing &<br />Careers
                  Fleet Management solutions
                </p>

                <div className="mt-4 flex gap-2">
                  {/* <div>
                    <img width={30} src="/images/facebook-icon.png" />
                  </div> */}
                  <div>
                    <a target="_blank" href="https://www.instagram.com/letsevleasing/">
                      <img width={30} src="/images/instagram-icon.png" />
                    </a>
                  </div>
                  <div>
                    <a target="_blank" href="https://www.linkedin.com/company/letsevleasing/">
                      <img width={30} src="/images/linkedin-icon.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-2 mt-1 sm:border-l-[1px] sm:border-b-[0px] border-b-[1px] h-fit sm:px-8 px-0 sm:pb-10 pb-4 sm:pt-0 pt-4'>
                <div>
                  <a href="/about-us" className='font-poppins sm:text-base text-xs'>About Us</a>
                </div>
                <div>
                  <a href="/solutions" className='font-poppins sm:text-base text-xs'>Solutions</a>
                </div>
                {/* <div>
                  <a href="/newsroom" className='font-poppins sm:text-base text-xs'>Newsroom</a>
                </div> */}
                <div>
                  <a href="/contact-us" className='font-poppins sm:text-base text-xs'>Contact Us</a>
                </div>
              </div>



              <div className='flex flex-col gap-2 sm:border-l-[1px] sm:border-b-[0px] border-b-[1px] h-fit sm:px-8 px-0 pb-3 sm:ml-24 ml-0 sm:pt-0 pt-4'>
                <div>
                  <p className='font-poppinsBold sm:text-2xl text-sm'>Contact Us</p>
                </div>
                <div>
                  <p className='font-poppins sm:text-base text-xs'>6th Floor, Capital Cyberscape, Dayma Road, Ullahwas,<br />
                    Sector 59, Gurugram, Haryana - 122102</p>
                </div>
                <div>
                  <div className='font-poppins sm:text-base text-xs flex gap-2 items-center mb-2'>
                    <img width={24} src="/images/phone-icon.png"></img>
                    +91 9821367634
                  </div>
                  <div className='font-poppins sm:text-base text-xs flex gap-2'>
                    <img width={24} src="/images/mail-icon.png"></img>
                    communications@letsevleasing.com
                  </div>
                </div>
              </div>
            </div>

            <div className='relative z-50 flex justify-between py-8 text-xs text-white font-myriad border-b-[1px]'>
              <div>
                2024 Let’sEV. All rights reserved
              </div>
              {/* <div className='flex gap-1'>
                <a href="">Privacy Policy |</a>
                <a href="">Cookie Policy |</a>
                <a href=""></a>Terms of Use
              </div> */}
            </div>

            <div className='relative z-50 mt-8 text-white'>
              <p className='font-poppinsBold sm:text-base text-xs'>Explore Let’sEV</p>
            </div>

            <div className='relative z-50 flex sm:flex-row flex-col text-white mt-2'>
              <div className='flex flex-col gap-2 mt-1 pr-20 sm:border-b-[0px] border-b-[1px] pl-0 py-4'>
                <div>
                  <p className='font-poppinsBold sm:text-base text-xs'>About Us</p>
                </div>
                <div>
                  <a href="/about-us" className='font-poppins sm:text-base text-xs'>About Let’sEV Leasing</a>
                </div>
                <div>
                  <a target="_blank" href="https://www.gensol.in/" className='font-poppins sm:text-base text-xs'>About Gensol<br />
                    Engineering Ltd</a>
                </div>
                <div>
                  <a href="/about-us" className='font-poppins sm:text-base text-xs'>Our Team / Leadership</a>
                </div>
                {/* <div>
                  <a href="/about-us" className='font-poppins sm:text-base text-xs'>Social responsibility </a>
                </div> */}
              </div>
              <div className='flex flex-col gap-2 mt-1 sm:border-l-[1px]  sm:border-b-[0px] border-b-[1px] sm:pl-8 pl-0 py-4 pr-20'>
                <div>
                  <p className='font-poppinsBold sm:text-base text-xs'>Solutions</p>
                </div>
                <div>
                  <a href="/solutions" className='font-poppins sm:text-base text-xs'>What we do</a>
                </div>
                <div>
                  <a href="/solutions" className='font-poppins sm:text-base text-xs'>Assets offered</a>
                </div>
                <div>
                  <a href="/solutions" className='font-poppins sm:text-base text-xs'>Complete Life Cycle<br />
                    Management of EV</a>
                </div>
                <div>
                  <a href="/solutions" className='font-poppins sm:text-base text-xs'>Type of Leases offered</a>
                </div>
                {/* <div>
                  <a href="/solutions" className='font-poppins sm:text-base text-xs'>Use Cases</a>
                </div>
                <div>
                  <a href="/solutions" className='font-poppins sm:text-base text-xs'>Project wins</a>
                </div> */}
              </div>
              {/* <div className='flex flex-col gap-2 mt-1 sm:border-l-[1px]  sm:border-b-[0px] border-b-[1px] sm:pl-8 pl-0 py-4 pr-20'>
                <div>
                  <a href="/newsroom" className='font-poppinsBold sm:text-base text-xs'>Newsroom</a>
                </div>
                <div>
                  <a href="/newsroom" className='font-poppins sm:text-base text-xs'>Press release</a>
                </div>
                <div>
                  <a href="/newsroom" className='font-poppins sm:text-base text-xs'>Media coverage</a>
                </div>
                <div>
                  <a href="/newsroom" className='font-poppins sm:text-base text-xs'>Press kit</a>
                </div>
                <div>
                  <a href="/newsroom" className='font-poppins sm:text-base text-xs'>Media contact </a>
                </div>
                <div>
                  <a href="/newsroom" className='font-poppins sm:text-base text-xs'>Events</a>
                </div>
                <div>
                  <a href="/newsroom" className='font-poppins sm:text-base text-xs'>Blogs</a>
                </div>
              </div> */}
              <div className='flex flex-col gap-2 mt-1 sm:border-l-[1px]  sm:border-b-[0px] border-b-[1px] sm:pl-8 pl-0 py-4 pr-20'>
                <div>
                  <p className='font-poppinsBold sm:text-base text-xs'>Contact Us</p>
                </div>
                <div>
                  <a href="/contact-us" className='font-poppins sm:text-base text-xs'>Contact Us</a>
                </div>
              </div>

            </div>

            <div className='absolute sm:top-0 top-[80%]'>
              <img src="/images/footer-bg.webp" />
            </div>
          </div>
        </div>
    </>
  );
};

export default RootLayout;
